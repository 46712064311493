import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='main' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/main_image.jpg)` }}>

    </div>
  );
}

export default App;
